import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Section } from "../../layout";
import { FindOutMoreButton, Image, DesktopRightImage, PhoneImage, TrailguidePlugin } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Image src="solutions/vpg_shop_outside.jpg" className="my-12 mx-auto" mdxType="Image" />
    <p className="justify-text leading-relaxed text-xl">
  <a href="https://www.vpg.no/">Vertical Playground</a> is a sport shop in
  Oppdal, Norway. The whole team consists of biking, skiing, climbing, hiking,
  or whitwater kayaking enthusiasts. VPG has the knowledge and provides the
  goods. Not only through their physical store, but also through their large and
  fast online shop.
    </p>
    <Section mdxType="Section" />
    <div className="w-full flex justify-center">
  <div className="w-4/5">
    <p className="px-12 pt-6 bg-gray-100 text-8xl font-bold">“</p>
    <p className="px-12 bg-gray-100 justify-text italic leading-relaxed text-xl">
      By advertising on Trailguide we can reach our target group, ...
      <br />
      <br />
      Trygve Sande, CEO VPG
    </p>
    <p className="px-12 bg-gray-100 text-8xl font-bold text-right">“</p>
  </div>
    </div>
    <Section mdxType="Section" />
    <h4>{`Reach your customers`}</h4>
    <DesktopRightImage className="-mt-8 w-1/2" src="solutions/vpg_ad_closeup.jpg" mdxType="DesktopRightImage" />
    <PhoneImage src="solutions/vpg_ad_closeup.jpg" mdxType="PhoneImage" />
    <p className="justify-text leading-relaxed text-xl">
  VPG is visible on Trailguide on the map and in the activity descriptions in a
  radius of 20km around their location. With our specific target group, their ad
  can achieve a high level of effectiveness. Only activity related services are
  visible in Trailguide. So the entry provides a value to the users and they can
  easily find what they need.
    </p>
    <Section mdxType="Section" />
    <TrailguidePlugin content="lat=62.594785700027934&lng=9.688137165340207&coverage=15" theme="light" mdxType="TrailguidePlugin" />
    <p className="text-xs -mt-8 mb-12 text-center">
  Interactive map with VPG in the center. Click on the icon. And find their ad
  in the activity descriptions.
    </p>
    <div className="flex justify-center mt-12">
  <FindOutMoreButton to="/solutions/bikeshop" className="text-xl" mdxType="FindOutMoreButton">
    Find out more about advertising on Trailguide
  </FindOutMoreButton>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      